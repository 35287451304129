import React, { forwardRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { siteUrl, localeRoot } from '@helpers/environment';

/**
 * A helper function that checks if the link is internal or external,
 * should it use the GatsbyLink component and what html attributes are required
 * eg. rel="nofollow", target="_blank", etc.
 * @param {string} href The links href attribute.
 * @param {boolean} isExternal Optional attribute to force isExternal state.
 * @returns {object} The link props.
 */
function getLinkProps(href, isExternal = false) {
  /*
   * Check whether the href is an internal or external link.
   * Anything that isn't using the site url or an alternative link
   * like mailto and tel should have additional props passed.
   */
  if (
    typeof href === `string` &&
    href.length > 0 &&
    href.indexOf(siteUrl) !== 0 &&
    href.match(`^(mailto|tel|sms|callto):`) === null
  ) {
    // External links - should use no follow and open in a new tab.
    const containsHTTPS = href.match(`^(http[s]?:)?//`) !== null;
    // eslint-disable-next-line no-useless-escape
    const isPDF = href.match(`^.*\.(pdf|PDF)$`) !== null;

    if (containsHTTPS === true || isPDF === true || isExternal === true) {
      if (isPDF === true) {
        const hrefWithLocale =
          localeRoot !== null ? `${localeRoot}${href}` : href;

        return {
          as: `a`,
          rel: `nofollow`,
          target: `_blank`,
          href: hrefWithLocale
        };
      }

      return { as: `a`, rel: `nofollow`, target: `_blank`, href };
    }
    // Internal links - this is likely a path which should use the GatsbyLink component.
    return { as: GatsbyLink, to: href, activeClassName: `is-active` };
  }

  /*
   * Fallback to a regular link for everything else.
   */
  return { as: `a`, href };
}

const withLink = (WrappedComponent) => {
  const Component = forwardRef(
    ({ href, isExternal = false, ...props }, ref) => {
      const filterProps = { ...props };
      delete filterProps.ref;

      const newProps = {
        ...getLinkProps(href, isExternal),
        ...filterProps
      };

      return <WrappedComponent {...newProps} />;
    }
  );

  return Component;
};

export default withLink;
