import React, { forwardRef } from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

import ChevronDownIcon from '@components/atoms/icons/ChevronDownIcon';
import ChevronUpIcon from '@components/atoms/icons/ChevronUpIcon';

export const AsButton = forwardRef(({ isOpen, ...props }, ref) => {
  return (
    <ChakraButton
      ref={ref}
      isActive={isOpen}
      rightIcon={
        isOpen ? (
          <ChevronUpIcon
            style={{
              width: `1.25rem`,
              height: `1.25rem`,
              marginLeft: `-0.25rem`
            }}
          />
        ) : (
          <ChevronDownIcon
            style={{
              width: `1.25rem`,
              height: `1.25rem`,
              marginLeft: `-0.25rem`
            }}
            color="primary.froneri-blue"
            sx={{ 'button:hover &': { color: `secondary.pink` } }}
          />
        )
      }
      {...props}
    />
  );
});

export default AsButton;
