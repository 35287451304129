export const colors = {
  primary: {
    'dark-blue': `#001C49`,
    'froneri-blue': `#00BAD5`
  },
  secondary: {
    pink: `#C04377`,
    orange: `#F5A858`,
    green: `#80EDA3`,
    yellow: `#F2EC94`,
    'mid-blue': `#658999`,
    'mid-grey': `#3A6067`,
    'light-grey': `#F5F5F5`,
    'pale-blue': `#E2F6FA`,
    'transparent-white': `rgba(255, 255, 255, 0.3)`,
    'chakra-grey-custom': `rgb(237, 242, 247)`,
    'navy-blue': `#000080`
  },
  brand: {
    oreo: `#0056A5`,
    extreme: `#00125A`,
    nuii: `#000000`,
    'haagen-dazs': `#5C1530`,
    outshine: `#275D38`,
    cadburys: `#4b0983`,
    dreyers: `#441105`,
    'dreyers-alt': `#F3BA44`,
    'skinny-cow': `#D8176E`,
    'skinny-cow-alt': `#512c1c`,
    aino: `#967D4B`,
    fazer: `#15256B`,
    pingviinin: `#00148C`,
    milka: `#7d68ac`,
    'milka-dark': `#3a2673`,
    'la-laitiere': `#ffd200`,
    maxibon: `#da291c`,
    kitkat: `#C8102E`,
    'la-lechera': `#ffd200`,
    antica: `#512f2e`
  },
  pl: {
    'zielona-budka': `#006f2e`,
    kaktus: `#e55c17`,
    'kaktus-2': `#006b38`,
    kitkat: `#de2516`,
    lion: `#4f0b08`,
    'lion-2': `#e65f17`,
    nesquik: `#ffe000`,
    'nesquik-2': `#093976`,
    movenpick: `#fcf2de`,
    daim: `#d62520`,
    'daim-2': `#143877`,
    nestea: `#5cc0ed`,
    'after-eight': `#021c0f`,
    'after-eight-2': `#0e8f3d`
  },
  gradient: {
    'overlay-dark': `#22293D`
  }
};
