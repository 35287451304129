import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Flex } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';
import getColorFromField from '@helpers/getColorFromField';
import linkResolver from '@helpers/linkResolver';

const NewsCarousel = ({
  data: {
    title,
    tagline,
    cta,
    entries,
    tags,
    backgroundColor,
    brandColor,
    readMoreButtonText = `Read story`,
    strapiComponent
  }
}) => {
  const textColor = getColorFromField(brandColor);
  const bgColor = getColorFromField(backgroundColor) || `white`;

  /**
   * Get list of latest entries in case we need them.
   */
  const staticData = useStaticQuery(graphql`
    {
      strapiArticleListing {
        slug
      }
      allStrapiArticle(sort: { publishDate: DESC }) {
        nodes {
          publishDate
          title
          slug
          tags {
            tagName
            tagId
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          internal {
            type
          }
        }
      }
      allStrapiProject(sort: { publishDate: DESC }) {
        nodes {
          publishDate
          title
          slug
          tags {
            tagName
            tagId
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          internal {
            type
          }
        }
      }
    }
  `);

  const {
    allStrapiArticle: { nodes: allArticles },
    // Note: Projects due to be removed so only requires article listing data
    allStrapiProject: { nodes: allProjects }
  } = staticData;

  const parentSlug =
    typeof staticData.strapiArticleListing !== `undefined`
      ? staticData.strapiArticleListing.slug
      : null;

  /**
   * Determine whether to use the latest entries or custom list.
   */
  const entriesList = useMemo(() => {
    const latest =
      strapiComponent === `sections.project-carousel`
        ? allProjects
        : allArticles;

    // Filter down the latest entries by tag if one has been selected.
    if (tags && tags.length > 0) {
      return latest.filter((entry) => {
        const filteredEntries = entry.tags.filter(
          ({ tagId }) => tags.some((selectedTag) => tagId === selectedTag.tagId)
          // eslint-disable-next-line function-paren-newline
        );

        return filteredEntries.length > 0;
      });
    }

    // Use the latest entries when no custom list is entered
    if (entries.length === 0) {
      return latest.slice(0, 6);
    }

    return entries;
  }, [entries, tags, strapiComponent, allArticles, allProjects]);

  return (
    <Section
      bg={bgColor}
      py={bgColor !== `white` ? { base: 12, md: 24 } : null}
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        },
        '.swiper-slide': {
          height: `auto`
        }
      }}>
      <Wrapper>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: `.prev`,
            nextEl: `.next`
          }}
          pagination={{
            el: `.pagination`,
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5
          }}
          spaceBetween={25}
          slidesPerView={1.15}
          breakpoints={{
            600: {
              slidesPerView: 1.5,
              spaceBetween: 30
            },
            768: {
              slidesPerView: 1.75,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 2.1,
              spaceBetween: 30
            },
            1200: {
              slidesPerView: 2.5,
              spaceBetween: 30
            }
          }}>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={
              <SliderArrows color={textColor} dataLayerType="section_title" />
            }
            color={textColor}
            slot="container-start"
            mb={{ base: 6, md: 10 }}
          />
          {entriesList &&
            entriesList.map((entry) => (
              <SwiperSlide key={entry.slug}>
                <Card
                  data={{
                    strapiComponent: `elements.news-card`,
                    title: entry.title,
                    date: entry.publishDate,
                    text: entry.text,
                    frontFace: entry.thumbnail,
                    cta: {
                      type: `internal`,
                      title: readMoreButtonText,
                      link: linkResolver({
                        parentSlug,
                        slug: entry.slug,
                        contentType: entry.internal.type.split(`_`).pop()
                      })
                    }
                  }}
                  sx={{
                    height: `100%`,
                    '.card-content': {
                      height: `100%`,
                      display: `flex`
                    },
                    '.card-cta-wrapper': {
                      display: `flex`
                    },
                    '.card-cta': {
                      marginTop: `auto`
                    }
                  }}
                />
              </SwiperSlide>
            ))}
          <Flex
            slot="container-end"
            align={{ md: `center` }}
            justify={{ md: `space-between` }}
            direction={{ base: `column`, md: `row-reverse` }}
            mt={{ base: 8, md: 16 }}>
            <SliderPagination color={textColor || `primary.froneri-blue`} />
            {cta && (
              <ButtonCms data={cta} color={textColor} mt={{ base: 8, md: 0 }} />
            )}
          </Flex>
        </Swiper>
      </Wrapper>
    </Section>
  );
};

export default NewsCarousel;
