import React, { useCallback, forwardRef } from 'react';
import { Box } from '@chakra-ui/react';
import Link from '@components/atoms/Link';
import dataLayerPush from '@helpers/dataLayerPush';

export const AsLink = forwardRef(
  (
    {
      children,
      url,
      title,
      rightIcon,
      onClick = () => {},
      onKeyDown = () => {},
      ...props
    },
    ref
  ) => {
    const handleClick = useCallback(() => {
      dataLayerPush({
        event: `nav_button_click`,
        interaction: {
          click_text: title,
          link_url: url,
          nav_level: 1
        }
      });
    }, [title, url]);

    return (
      <Link
        ref={ref}
        display="flex"
        href={url}
        title={title}
        h="14"
        px="1.2rem"
        py="0.5"
        color="primary.dark-blue"
        fontFamily="heading"
        fontWeight="600"
        lineHeight="1.2"
        borderRadius="xs"
        onClick={handleClick}
        sx={{
          span: {
            alignSelf: `center`
          }
        }}
        {...props}>
        {children}
        {rightIcon && (
          <Box
            as="span"
            marginLeft="auto"
            // Vertically center text with padding to accomodate for odd line height.
            pt=".125em">
            {rightIcon}
          </Box>
        )}
      </Link>
    );
  }
);

export default AsLink;
