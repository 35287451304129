import React, { useCallback } from 'react';

import {
  Link,
  Box,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Button as ChakraButton
} from '@chakra-ui/react';

import { Link as GatsbyLink, navigate } from 'gatsby';
import LinkComponent from '@components/atoms/Link';
import ChevronDownIcon from '@components/atoms/icons/ChevronDownIcon';
import ChevronUpIcon from '@components/atoms/icons/ChevronUpIcon';
import ArrowRightIcon from '@components/atoms/icons/ArrowRightIcon';
import { stringToId } from '@helpers/stringFormatters';
import dataLayerPush from '@helpers/dataLayerPush';

const NavDropMobile = ({ menuItem }) => {
  const renderAsButton = menuItem.attributes.children.data.length > 0;
  const mainButtonIsExternal = menuItem.attributes.target === `_blank`;

  const navProps =
    renderAsButton === true
      ? {}
      : {
          url: menuItem.attributes.url,
          href: menuItem.attributes.url,
          title: menuItem.attributes.title,
          target: menuItem.attributes.target,
          isExternal: mainButtonIsExternal
        };

  const handleNavigate = useCallback((url, title) => {
    dataLayerPush({
      event: `nav_button_click`,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: 1
      }
    });

    navigate(url);
  }, []);

  return (
    <AccordionItem
      id={`nav-drop-mobile-for-${stringToId(menuItem.attributes.title)}`}
      border="none"
      color="#fff">
      {({ isExpanded }) => (
        <>
          <AccordionButton
            fontSize="2xl"
            fontFamily="heading"
            py="5px"
            as={renderAsButton === true ? AccordionButton : LinkComponent}
            // eslint-disable-next-line consistent-return
            onClick={() => {
              if (menuItem.attributes.children.data.length <= 1) {
                return handleNavigate(
                  menuItem.attributes.url,
                  menuItem.attributes.title
                );
              }
            }}
            {...navProps}>
            {menuItem.attributes.title}
            {menuItem.attributes.children.data.length > 0 &&
              (isExpanded === true ? (
                <ChevronUpIcon
                  ml="4px"
                  color="secondary.pink"
                  style={{ width: `1.25rem`, height: `1.25rem` }}
                />
              ) : (
                <ChevronDownIcon
                  ml="4px"
                  color="primary.froneri-blue"
                  style={{ width: `1.25rem`, height: `1.25rem` }}
                />
              ))}
          </AccordionButton>
          {menuItem.attributes.children.data.length > 0 && (
            <AccordionPanel>
              {menuItem.attributes.children.data.map((link, index) => {
                const linkIsExternal = link.attributes.target === `_blank`;

                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${link.attributes.title.toLowerCase()}-${index}`}
                    mb="8px"
                    transition={`opacity 0.05s ease-in ${index * 0.05}s`}
                    opacity={isExpanded ? `1` : `0`}>
                    {linkIsExternal === true ? (
                      <LinkComponent
                        href={link.attributes.url}
                        target={menuItem.attributes.target}
                        variant="header"
                        color="#fff"
                        fontSize="sm"
                        fontWeight="800"
                        isExternal
                        onClick={
                          () =>
                            handleNavigate(
                              link.attributes.url,
                              link.attributes.title,
                              2
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }>
                        {link.attributes.title}
                      </LinkComponent>
                    ) : (
                      <Link
                        as={GatsbyLink}
                        to={link.attributes.url}
                        variant="header"
                        color="#fff"
                        fontSize="sm"
                        fontWeight="800"
                        onClick={
                          () =>
                            handleNavigate(
                              link.attributes.url,
                              link.attributes.title,
                              2
                            )
                          // eslint-disable-next-line react/jsx-curly-newline
                        }>
                        {link.attributes.title}
                      </Link>
                    )}
                  </Box>
                );
              })}
              {mainButtonIsExternal === true ? (
                <LinkComponent
                  href={menuItem.attributes.url}
                  target={menuItem.attributes.target}
                  isExternal
                  transition={`opacity 0.05s ease-in ${
                    menuItem.attributes.children.data.length * 0.05
                  }s`}>
                  <ChakraButton
                    mt="5px"
                    backgroundColor="#fff"
                    color="primary.dark-blue"
                    rightIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <ArrowRightIcon
                        color="primary.froneri-blue"
                        h="5"
                        w="5"
                      />
                    }>
                    {menuItem.attributes.title}
                  </ChakraButton>
                </LinkComponent>
              ) : (
                <Link
                  as={GatsbyLink}
                  to={menuItem.attributes.url}
                  transition={`opacity 0.05s ease-in ${
                    menuItem.attributes.children.data.length * 0.05
                  }s`}>
                  <ChakraButton
                    mt="5px"
                    backgroundColor="#fff"
                    color="primary.dark-blue"
                    rightIcon={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <ArrowRightIcon
                        color="primary.froneri-blue"
                        h="5"
                        w="5"
                      />
                    }>
                    {menuItem.attributes.title}
                  </ChakraButton>
                </Link>
              )}
            </AccordionPanel>
          )}
        </>
      )}
    </AccordionItem>
  );
};

export default NavDropMobile;
